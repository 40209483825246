import en from './locales/en.json'

export default defineI18nConfig(() => ({
  locale: 'en',
  fallbackLocale: 'en',
  fallbackWarn: false,
  missingWarn: false,
  messages: {
    en, // English
  },
  escapeParameter: true,
  warnHtmlMessage: true,
  legacy: false,
}))
